/* -------------------------------------------------------------------------

	PUNITED.common

	サイト内で共通のスクリプトを定義
	※jQuery必須
	Potential United inc.

------------------------------------------------------------------------- */

// グローバル名前空間定義
var PUNITED;
if (!PUNITED) PUNITED = {};
if (!PUNITED.common) PUNITED.common = {};

(function () {
	// グローバル名前空間オブジェクトをローカルオブジェクトに置き換え
	var ns = PUNITED.common;
	var _isMobile = (/Android|iPhone|iPod|BlackBerry/i).test(navigator.userAgent || navigator.vendor || window.opera);

	/* -----------------------------------------------------
		IE10以下の場合にメッセージを表示
	----------------------------------------------------- */
	if ($.browser.is('msie') && $.browser.version.isOrLess(10)) {
		$("html").addClass("regacy_ie");
	}

	/* -----------------------------------------------------
		Touchデバイスの場合にクラス付与
	----------------------------------------------------- */

	function is_touch_device() {
		return (('ontouchstart' in window)
			|| (navigator.MaxTouchPoints > 0)
			|| (navigator.msMaxTouchPoints > 0));
	}

	if (is_touch_device()) {
		$("html").addClass("is_touch");

		$('.is_touch_disable, .nav_btn').on('touchstart', function (e) {
			$('.is_touch_disable').removeClass('hover');
			$(this).addClass('hover');
		});
	}

	/* -----------------------------------------------------

	----------------------------------------------------- */
	ns.NavigationHover = function () {

		var _link = $('.global_nav_list__item');
		var _obj = PUNITED.useful.checkBreakPont([768]);
		var _vpMobile = false;

		_obj.on("onPointChanged onPointInit", function (event, point) {
			switch (point) {
				case 1:
					_vpMobile = true;
					break;
				default:
					_vpMobile = false;
					break;
			}
		});

		_link.hover(function () {
			if (_vpMobile) return false;
			_link.addClass('global_nav_list__item--opacity');
			$(this).removeClass('global_nav_list__item--opacity');
		}, function () {
			if (_vpMobile) return false;
			_link.removeClass('global_nav_list__item--opacity');
		});

	}

	ns.toggleNavigation = function () {

		var _permalink = $('.global_nav__list__item__toggle').prev().prev();
		var _obj = PUNITED.useful.checkBreakPont([768]);

		_obj.on("onPointChanged onPointInit", function (event, point) {
			switch (point) {
				case 1:
					_vpMobile = true;
					_permalink.on('click.toggleEvent', function (event) {
						event.preventDefault();
						$(this).next().stop().slideToggle();
						$(this).next().next().toggleClass('-active');
					});
					break;
				default:
					_vpMobile = false;
					_permalink.off('click.toggleEvent');
					$('.global_nav__child_list').removeAttr('style');
					break;
			}
		});
	}

	ns.Accordion = function () {
		var _elem = $('.accordion');
		var _elemCount = 0;
		var _elemLength = _elem.length;

		if (_elem.length < 1) return false;

		_elem.each(function () {
			var _item = $('.accordion__title', this);
			var _content = $('.accordion__content', this);
			var _originHeight = _content.height();
			var _isOpen = false;

			$(window).on('load', function () {
				_originHeight = _content.height();
				_elemCount++;

				if (_item.data('status') == 'open') {
					_isOpen = true;
					_item.addClass('-active');
				} else {
					_content.height(0);
				}

				_item.on('click', function () {
					if (_isOpen) {
						_isOpen = false;
						$(this).next().height(0);
					} else {
						_isOpen = true;
						$(this).next().height(_originHeight);
					}

					$(this).toggleClass('-active');
				})

				loadedEvent();
			});
		})

		function loadedEvent() {
			if (_elemCount == _elemLength) {
				if (window.location.hash) {
					var anchor = location.hash;
					var posY = $(anchor).offset().top;
					$('html, body').scrollTop(posY);
				}

			}
		}

	}

	ns.mobileNavigation = function () {

		var _btnNav = $('.nav_btn');
		var _label = $('.nav_btn__label');
		var _body = $('body');
		var _nav = $('.global_nav');
		var _recruitNav = $('.recruit_nav');
		var _event = '';
		var _status = 'close';
		var scrollEvent = 'onwheel' in document ? 'wheel' : 'onmousewheel' in document ? 'mousewheel' : 'DOMMouseScroll';
		var _currentPos = 0;

		_event = 'click';

		_btnNav.on(_event, function () {

			if (_status == 'close') {
				_currentPos = $(window).scrollTop();
				_status = 'open';
				_btnNav.addClass('-open');
				_nav.addClass('-open');
				_recruitNav.addClass('-open');
				_label.html('CLOSE');

				$('.main, .site_footer').hide();
				$('body').css('overflow-y', 'scroll');

			} else if (_status == 'open') {
				_status = 'close';
				_btnNav.removeClass('-open');
				_nav.removeClass('-open');
				_recruitNav.removeClass('-open');
				$('.main, .site_footer, body').removeAttr('style');

				_label.html('MENU');
				$(window).scrollTop(_currentPos);

				if ($('.carousel').length > 0) {
					$('.carousel').slick('setPosition');
				}

			}
		})
	}
	ns.initModal = function () {

		var _mapElem = $('.js-modal-gmap');
		var _thumbElem = $('.js-modal-thumb');


		if (!_mapElem.length && !_thumbElem.length) {
			return false;
		}

		var _html = '<div class="modal_ap"><div class="modal_ap__inner"><div class="modal_ap__body"></div><div class="modal_ap__close"><button>閉じる</button></div></div></div>';


		$('body').prepend(_html);
		$('.modal_ap').hide();
		var _modal = $('.modal_ap');

		_mapElem.on('click', function () {
			var _latlng = $(this).data('gmap');
			_latlng = _latlng.replace(/\s+/g, "");

			$('#gmap').remove();
			$('.modal_ap__link').remove();
			$('.modal_ap__body').append('<div id="gmap" />');
			$('.modal_ap__body').append('<p class="modal_ap__link"><a href="https://www.google.com/maps/?q=' + _latlng + '" target="_blank" class="permalink_blank">Google マップで見る</a></p>');

			createGmap(_latlng);
			$('.modal_ap').fadeIn(500);
			return false;
		})

		_thumbElem.on('click', function () {

			var _img = $(this).attr('href');

			$('.modal_ap__photo').remove();
			$('.modal_ap__body').append('<div class="modal_ap__photo"><img src="' + _img + '" ></div>');

			$('.modal_ap').fadeIn(500);
			return false;
		})
		_modal.on('click', function (e) {

			if (e.target.className == 'modal_ap') {
				$('.modal_ap').fadeOut(500, function () {
					$('#gmap').remove();
					$('.modal_ap__photo').remove();
					$('.modal_ap__link').remove();
				});
			}
		})

		$('.modal_ap__close button').on('click', function () {
			$('.modal_ap').fadeOut(500, function () {
				$('#gmap').remove();
				$('.modal_ap__photo').remove();
				$('.modal_ap__link').remove();
			});
			return false;
		});

		function createGmap(_latlng) {

			_latlng = _latlng.split(',');
			Number(_latlng);

			var _myLatLng01 = { lat: Number(_latlng[0]), lng: Number(_latlng[1]) };
			var _image = {
				url: '/assets/img/common/img_gmap_parking.png',
				scaledSize: new google.maps.Size(43, 59)
			}
			var _mapOptions01 = {
				zoom: 14,
				center: _myLatLng01,
				disableDefaultUI: true,
				zoomControl: true,
				zoomControlOptions: {
					position: google.maps.ControlPosition.TOP_LEFT,
					style: google.maps.ZoomControlStyle.DEFAULT
				},
				mapTypeControlOptions: {
					mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
				}
			};

			var _map01 = new google.maps.Map(document.getElementById('gmap'), _mapOptions01);

			var _marker01 = new google.maps.Marker({
				position: _myLatLng01,
				map: _map01,
				icon: _image
			});
		}
	}
	ns.changeViewport = function () {
		var _vp = $('#vp');
		if (!_isMobile) {
			_vp.attr('content', 'width=1400');
		}
	}
	ns.togglePagetop = function () {
		var _wy = 0;
		var _pagetop = $('.page_top');

		window.addEventListener('scroll', function () {
			_wy = document.documentElement.scrollTop || document.body.scrollTop;

			if (_wy > 100) {
				_pagetop.addClass('page_top--show');
				_pagetop.removeClass('page_top--hidden');
			} else {
				_pagetop.addClass('page_top--hidden');
				_pagetop.removeClass('page_top--show');
			}
		})
	}
	ns.phoneCheck = function () {
		var $phone = $('a[href^="tel:"]');
		if (!_isMobile) {
			$phone.addClass('phone__disabled').on('click', function (e) {
				e.preventDefault();
			}).attr('href', '#');
		}
	}
	ns.scrollAnimation = function (elem) {
		var self = this;
		var _wy = 0;
		var _wh = window.innerHeight;
		var _elem = document.querySelectorAll(elem);

		var _mag = 0.75;
		var _delay = 300;

		if (_isMobile) {
			_mag = 0.9;
		}

		window.addEventListener('scroll', function () {
			_wy = $(window).scrollTop();
		})
		window.addEventListener('resize', function () {
			_wh = $(window).height();
		})
		_wy = $(window).scrollTop();

		var node = Array.prototype.slice.call(_elem, 0);

		node.forEach(function (elem) {
			var _interval = 0;
			elem.targetPosY = $(elem).offset().top;
			window.addEventListener('load', function () {
				elem.targetPosY = $(elem).offset().top;
			});

			window.addEventListener('scroll', scrollEvent);
			window.addEventListener('load', loadEvent);
			// scrollEvent();
			loadEvent();

			function scrollEvent() {
				if (_wy + _wh * _mag > elem.targetPosY) {

					window.removeEventListener('scroll', scrollEvent);
					elem.classList.add("init");

				}
			}
			function loadEvent() {
				if (_wy + _wh > elem.targetPosY) {

					window.removeEventListener('scroll', scrollEvent);
					if (!_isMobile) {
						if (elem.dataset.saDuration != undefined) {
							_interval = elem.dataset.saDuration;
						}
					}

					setTimeout(function () {
						elem.classList.add("init");
					}, _interval * 1000)

				}
			}
		});
	}
	ns.newIcon = function () {
		var _newHtml = '<p class="archive_list__new">NEW</p>';
		$('.archive_list__date').each(function () {

			var entryDate = $(this).html();
			entryDate = entryDate.split('.');
			entryDate = entryDate[1] + '/' + entryDate[2] + '/' + entryDate[0];

			var current = new Date();

			var range_ms = current.getTime() - (30 * 24 * 60 * 60 * 1000);
			var modified = new Date(entryDate);
			var modified_ms = modified.getTime();
			if (range_ms < modified_ms) {
				$(this).after(_newHtml);
			}
		});
	}
	ns.globalNavigation = function () {
		var _parentLink = $('.global_nav__child li a');

		_parentLink.on('blur', function () {
			$(this).parent().parent().prev().removeClass('focus');
		})
		_parentLink.on('focus', function () {
			$(this).parent().parent().prev().addClass('focus');
		})
	}

	ns.tab = function () {
		var _elem = $('.js_tab');

		if (_elem.length < 1) return false;

		_elem.each(function () {
			var $permalink = $('a', $(this));

			$permalink.each(function () {
				var _selector = $(this).attr('href');
				$(_selector).hide();
			});

			$($permalink.eq(0).attr('href')).show();
			$permalink.eq(0).parent().addClass('-current')

			$permalink.on('click', function () {
				event.preventDefault();

				var _target = $(this).attr('href');

				$permalink.each(function () {
					var _selector = $(this).attr('href');
					$(_selector).hide();
				});

				$(_target).show();
				$permalink.parent().removeClass('-current');
				$(this).parent().addClass('-current')

			})
		});
	}
	ns.navCurrentScroll = function (elem) {
	}


})();

$(window).load(function () {
	// PUNITED.useful.externalInnerPageLink('anc', 90);
	PUNITED.useful.innerPageLink(".page_nav a", 40);
});

$(function () {
	PUNITED.useful.replaceImage("data-sp", 640);
	// PUNITED.common.scrollAnimation('.st');
	PUNITED.common.toggleNavigation();
	PUNITED.common.Accordion();
	PUNITED.common.mobileNavigation();
	PUNITED.common.globalNavigation();
	PUNITED.common.changeViewport();
	// PUNITED.common.NavigationHover();
	PUNITED.common.togglePagetop();
	PUNITED.common.phoneCheck();
	// PUNITED.common.initModal();
	// PUNITED.common.newIcon();
	PUNITED.common.tab();

});
